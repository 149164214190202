import axios from 'axios'
import Auth from '@/auth/auth-token'

class Document {
  constructor () {
    const base = axios.create({
      baseURL: process.env.VUE_APP_DOCUMENT_API,
      withCredentials: false,
      headers: {
        'Content-type': 'application/json'
      }
    })
    this.document = base
  }

  async getFile (id) {
    await Auth.setAuth(this.document, {
      audience: process.env.VUE_APP_DOCUMENT_AUDIENCE
    })
    return this.document({
      url: `/doc/${id}/file`,
      method: 'GET'
    })
  }
}

export default new Document()