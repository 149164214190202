var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { value: _vm.value, justify: "center", width: "1160px" },
      on: {
        "click:outside": function($event) {
          return _vm.$emit("close")
        }
      }
    },
    [
      _c(
        "v-card",
        { staticStyle: { position: "relative", height: "700px" } },
        [
          _c(
            "v-btn",
            {
              attrs: { icon: "", absolute: "", top: "", right: "" },
              on: {
                click: function($event) {
                  return _vm.$emit("close")
                }
              }
            },
            [_c("v-icon", [_vm._v("mdi-close")])],
            1
          ),
          _c(
            "div",
            { staticClass: "player-container" },
            [
              _vm.loading
                ? _c("v-progress-circular", {
                    attrs: { indeterminate: "", size: "64" }
                  })
                : _c("video-player", {
                    staticClass: "vjs-custom-skin",
                    attrs: { options: _vm.playerOptions }
                  })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }